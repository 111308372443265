<template>
    <n-list value="orders" text="訂單" @updateShop='getShop' :shopOptions="shopOptions" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="orderByShop" :selectShop='true' :disabled="true" />
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
    data() {
        return {
            shop: null,
            order: null,
            sortBy: 'uniNum',
            sortDesc: true,
            fields: [
                {
                    key: 'uniNum',
                    label: '單號',
                    sortable: true,
                },
                {
                    key: 'source',
                    label: '來源',
                    sortable: true,
                },
                {
                    key: 'method',
                    label: '方式',
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                    formatter: value => {
                        switch (value) {
                            case 'PICKUP':
                                return '自取'
                            case 'DELIVERY':
                                return '外送'
                        }
                    },
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
                {
                    key: 'status',
                    label: '狀態',
                    sortable: true,
                    formatter: value => {
                        switch (value) {
                            case 'PENDING':
                                return '待接單'
                            case 'RECEIVED':
                                return '已接單'
                            case 'PENDING_PAYMENT':
                                return '待付款'
                            case 'PACKED':
                                return '待取餐'
                            case 'COMPLETED':
                                return '已完成'
                            case 'VOID':
                                return '已取消'
                        }
                    },
                },
            ],
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'orders', merchantId: localStorage.merchantId })
        this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            shops: state => state.shops,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    }
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        orderByShop(){
            let res = []
            if (!this.shop){
                _.cloneDeep(this.orders).forEach(order => {
                return res.push(order)
                })
            }
            else{ 
                _.cloneDeep(this.orders).forEach(order => {
                    if (order.shop.id == this.shop.id) return res.push(order)
                })
            }
            return res
        }
    },

    methods: {
        getShop(shop){
            this.shop = shop;
        },
    }
}
</script>
